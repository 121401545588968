import type { SliderCollectionResponse } from '@/data/__generated__/types/generated';

import Image from 'next/image';

import { getImageSource } from '@/helpers';
import Link from 'next/link';

type SliderItemProps = {
  collection: SliderCollectionResponse;
};

const SliderItem: React.FC<SliderItemProps> = ({ collection }) => {
  return (
    <Link href={`/catalog/${collection?.slug}`}>
      <div>
        <Image
          className='object-cover sm:max-h-[200px]'
          priority
          width={894}
          height={448}
          alt={collection?.title}
          src={getImageSource({
            height: 1280,
            width: 1920,
            thumbnail: collection?.image || '',
            type: 'collections',
            brand: collection?.slug,
          })}
        />
      </div>
    </Link>
  );
};

export default SliderItem;
