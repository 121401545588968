import type { SliderCollectionResponse } from '@/data/__generated__/types/generated';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import SliderItem from '../SliderItem';
import SliderText from '../SliderText';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

type SliderProps = {
  setSlideIndex: React.Dispatch<React.SetStateAction<number>>;
  slideIndex: number;
  sliderCollections: SliderCollectionResponse[];
};

const Slider: React.FC<SliderProps> = ({
  sliderCollections,
  setSlideIndex,
  slideIndex,
}) => {
  if (!sliderCollections || !sliderCollections.length) return null;
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        onSlideChange={({ activeIndex }) => setSlideIndex(activeIndex)}
        className='rounded-3xl overflow-hidden'
        pagination={{
          el: '.swiper-pagination',
          dynamicBullets: false,
          clickable: true,
        }}
        autoplay={{
          delay: 4000,
          pauseOnMouseEnter: true,
        }}
      >
        {sliderCollections.map((collection) => {
          return (
            <SwiperSlide key={collection?.id}>
              <SliderItem collection={collection} />
            </SwiperSlide>
          );
        })}
        <SliderText activeSlide={sliderCollections[slideIndex]} />
      </Swiper>
    </>
  );
};

export default Slider;
